export const revalidate = 3600
import nookies from 'nookies'
import dynamic from 'next/dynamic'

import { getApiKey, isMobile as isMobileUtil } from '@utils'
import TadaService from '../services/TadaService'
import { DEFAULT_SHOP_ID } from '@static/constants'
const HomePage = dynamic(() => import('@components/pages/home'))

export default function Home({ banners, specials, seasonalGoods, popular, newGoods, isMobile }) {
   return (
      <HomePage
         banners={ banners }
         specials={ specials }
         seasonalGoods={ seasonalGoods }
         popular={ popular }
         newGoods={ newGoods }
         isMobile={ isMobile }
      />
   )
}

export async function getServerSideProps({ ...ctx }) {
   const tadaService = new TadaService(getApiKey())
   const cookies = nookies.get(ctx)
   const user = cookies.user
   const shopId = cookies.shopId || DEFAULT_SHOP_ID
   const isMobile = isMobileUtil(ctx.req.headers['user-agent'])

   if (user) {
      ctx.res.setHeader('Cache-Control', 'no-store')
   } else {
      ctx.res.setHeader('Cache-Control', 's-maxage=60, must-revalidate')
   }

   if (isMobile) {
      ctx.res.setHeader('Vary', 'Authorization, User-Agent, Mobile')
   } else {
      ctx.res.setHeader('Vary', 'Authorization, User-Agent, Desktop')
   }

   const fulfilled = await Promise.allSettled([
      tadaService.bannersList(),
      tadaService.specialsList(shopId, 30),
      tadaService.seasonalGoods(shopId),
      tadaService.popularList({ shopId }),
      tadaService.newGoodsList({
         categoryId: null,
         shopId,
         filterInStock: true,
         filterPrice: null,
         sort: { direction: 'ASC', limit: 10, offset: 0, order: 'none' },
         filterAttributes: null,
         options: { previewSize: '177x177' }
      })
   ])
   for (let i = 0; i <= fulfilled.length; i++) {
      const item = fulfilled[i]

      if (item) {
         if (item.status === 'rejected') {
            tadaService.logError(item.reason)
            fulfilled[i] = item.reason
         } else {
            fulfilled[i] = item.value
         }
      }
   }

   const [
      banners,
      specials,
      seasonalGoods,
      popular,
      newGoods
   ] = fulfilled

   return {
      props: {
         banners,
         specials,
         seasonalGoods,
         popular,
         newGoods,
         isMobile
      }
   }
}
